export const POLYGON_TEST_ID = '0x13882';
export const POLYGON_ID = '0x89';
export const SMART_CHAIN_TEST_ID = '0x61';
export const SMART_CHAIN_ID = '0x38';
export const POLYGON_URI_PROMO_TEST =
  'https://api.studio.thegraph.com/query/22608/mintme-promo-amoy/version/latest';
export const POLYGON_URI_PROMO_PROD =
  'https://api.studio.thegraph.com/query/21669/mint-me-promo-matic/version/latest';

export const configNetList: { test: ChainDataType[]; prod: ChainDataType[] } = {
  test: [
    {
      id: POLYGON_TEST_ID,
      rpcUrls: ['https://polygon-amoy.drpc.org'],
      blockExplorerUrls: ['https://amoy.polygonscan.com/'],
      name: 'Polygon Testnet',
      nameForUrl: 'amoy',
      currency: 'POL',
      decimals: 18,
      scanAddress: 'https://amoy.polygonscan.com/address',
      tgUriMintMe:
        'https://api.studio.thegraph.com/query/22608/mintme-amoy/version/latest',
      tgUriProveMe:
        'https://api.studio.thegraph.com/query/22608/proveme-amoy/version/latest',
      tgUriPromo: POLYGON_URI_PROMO_TEST
    },
    {
      id: '0x3',
      rpcUrls: ['https://ropsten.infura.io/v3/'],
      blockExplorerUrls: ['https://ropsten.etherscan.io/'],
      name: 'Ropsten',
      nameForUrl: 'ropsten',
      currency: 'ETH',
      decimals: 18,
      scanAddress: 'https://ropsten.etherscan.io/address',
      tgUriMintMe:
        'https://api.thegraph.com/subgraphs/name/statzky/mintme-ropsten',
      tgUriProveMe: '',
      tgUriPromo: ''
    },
    {
      id: SMART_CHAIN_TEST_ID,
      rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
      blockExplorerUrls: ['https://testnet.bscscan.com/'],
      name: 'Binance Smart Chain Test',
      nameForUrl: 'bsc-test',
      scanAddress: 'https://testnet.bscscan.com/address',
      currency: 'BNB',
      decimals: 18,
      tgUriMintMe: 'https://api.thegraph.com/subgraphs/name/statzky/mintme-bsc',
      tgUriProveMe: '',
      tgUriPromo: ''
    }
  ],
  prod: [
    {
      id: POLYGON_ID,
      rpcUrls: ['https://polygon-rpc.com/'],
      blockExplorerUrls: ['https://polygonscan.com/'],
      name: 'Polygon',
      nameForUrl: 'polygon',
      currency: 'POL',
      decimals: 18,
      scanAddress: 'https://polygonscan.com/address',
      tgUriMintMe:
        'https://api.studio.thegraph.com/query/21669/mint-me-polygon/version/latest',
      tgUriProveMe:
        'https://api.studio.thegraph.com/query/21669/prove-me/version/latest',
      tgUriPromo: POLYGON_URI_PROMO_PROD
    },
    {
      id: '0x1',
      blockExplorerUrls: ['https://mainnet.infura.io/v3/'],
      rpcUrls: ['https://etherscan.io/'],
      name: 'Ethereum',
      nameForUrl: 'ethereum',
      currency: 'ETH',
      decimals: 18,
      scanAddress: 'https://etherscan.io/address',
      tgUriMintMe:
        'https://api.studio.thegraph.com/query/21669/mint-me-ethereum/version/latest',
      tgUriProveMe: '',
      tgUriPromo: ''
    },
    {
      id: SMART_CHAIN_ID,
      blockExplorerUrls: ['https://bsc-dataseed1.binance.org/'],
      rpcUrls: ['https://bscscan.com/'],
      name: 'Binance Smart Chain',
      nameForUrl: 'bsc',
      currency: 'BNB',
      decimals: 18,
      scanAddress: 'https://bscscan.com/address',
      tgUriMintMe:
        'https://api.studio.thegraph.com/query/21669/mint-me-bsc/version/latest',
      tgUriProveMe: '',
      tgUriPromo: ''
    }
  ]
};
